import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CheckboxDisabledIndeterminateIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <rect x="2" y="2" width="20" height="20" rx="6" fill="#CBD5E1" />
            <path
                d="M16.2857 12.5H7.71429C7.32143 12.5 7 12.1625 7 11.75C7 11.3375 7.32143 11 7.71429 11H16.2857C16.6786 11 17 11.3375 17 11.75C17 12.1625 16.6786 12.5 16.2857 12.5Z"
                fill="white"
            />
        </SvgIcon>
    );
};

export default CheckboxDisabledIndeterminateIcon;
