import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CheckboxDefaultCheckedIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <rect
                x="2"
                y="2"
                width="20"
                height="20"
                rx="6"
                fill="currentColor"
            />
            <path
                d="M8.29661 12.445C8.06788 12.2052 7.68809 12.1963 7.44832 12.425C7.20856 12.6537 7.19962 13.0335 7.42835 13.2733L9.81007 15.7699C10.0553 16.0269 10.469 16.0158 10.7 15.7459L16.4274 9.05479C16.6429 8.80304 16.6135 8.42428 16.3618 8.2088C16.11 7.99332 15.7313 8.02271 15.5158 8.27445L10.5079 14.125C10.3539 14.3049 10.0781 14.3123 9.91458 14.141L8.29661 12.445Z"
                fill="white"
            />
        </SvgIcon>
    );
};

export default CheckboxDefaultCheckedIcon;
