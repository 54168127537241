const TableIsEmptyIconStopFragment = () => {
    return (
        <>
            <stop stopColor="#A0D0FF" />
            <stop offset="0.13" stopColor="#A0D0FF" stopOpacity="0.98" />
            <stop offset="0.25" stopColor="#A0D0FF" stopOpacity="0.94" />
            <stop offset="0.38" stopColor="#A0D0FF" stopOpacity="0.86" />
            <stop offset="0.5" stopColor="#A0D0FF" stopOpacity="0.75" />
            <stop offset="0.63" stopColor="#A0D0FF" stopOpacity="0.6" />
            <stop offset="0.76" stopColor="#A0D0FF" stopOpacity="0.43" />
            <stop offset="0.88" stopColor="#A0D0FF" stopOpacity="0.23" />
            <stop offset="1" stopColor="#A0D0FF" stopOpacity="0" />
        </>
    );
};

export default TableIsEmptyIconStopFragment;
