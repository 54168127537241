import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CheckboxErrorIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="7"
                fill="white"
                stroke="#FEF2F2"
                strokeWidth="2"
            />
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="5.5"
                fill="white"
                stroke="#B91C1C"
            />
        </SvgIcon>
    );
};

export default CheckboxErrorIcon;
