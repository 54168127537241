import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CheckboxDisabledIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="5.5"
                fill="#E2E8F0"
                stroke="#CBD5E1"
            />
        </SvgIcon>
    );
};

export default CheckboxDisabledIcon;
